require('@rails/ujs').start()

import 'jquery'
import 'popper.js'
import 'bootstrap'

import '../src/typeahead.js';
import '../src/custom.js';

import WebpackerSvelte from "webpacker-svelte";
import subsidies from "src/pages/subsidies.svelte";
import SubsidyPreview from "src/components/SubsidyPreview.svelte";

WebpackerSvelte.setup({
  subsidies,
  SubsidyPreview
});

// Images
require.context('../images', true);